<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        type="primary"
        round
        icon="iconxinzeng"
        @click="
          currentItem = null
          visible = true
        "
        >{{ $t('newadd') }}</c-button
      >
    </div>

    <div class="mainCot" v-loading="loading">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('marketManagement.launchProgress')"
          :name="$t('marketManagement.launchProgress')"
        />
        <el-tab-pane
          :label="$t('marketManagement.released')"
          :name="$t('marketManagement.released')"
        />
        <el-tab-pane
          :label="$t('marketManagement.complete')"
          :name="$t('marketManagement.complete')"
        />
      </el-tabs>
      <div class="contentWrap" v-if="programList.length">
        <div
          @click="detailHandler(item)"
          class="listItem"
          v-for="item in programList"
          :key="item.id"
        >
          <el-card>
            <div class="el-card-top">
              <span v-if="item.isLongTermDelivery == 0">{{ $t('loginPage.longTerm') }}</span>
              <span v-else
                >{{ $t('marketManagement.asOf')
                }}{{ $utils.formatTime(item.endDate, 'YYYY-MM-DD') }}</span
              >
              <span>{{ activeName }}</span>
            </div>
            <div class="main">
              <img v-if="+item.type === 1 || item.isImage" :src="item.path" />
              <video v-else @click.stop :src="item.path"></video>
            </div>

            <div class="el-card-bottom">
              <div>
                <el-tag v-if="+item.type === 1" type="primary">{{
                  $t('spaceDetails.photo')
                }}</el-tag>
                <el-tag v-else-if="+item.type === 2" type="success ">{{
                  $t('spaceDetails.video')
                }}</el-tag>
                <el-tag v-else type="success "
                  >{{ $t('spaceDetails.photo') }}+{{ $t('spaceDetails.video') }}</el-tag
                >
              </div>

              <div style="z-index:100">
                <i class="el-icon-edit" @click.stop="updateHandler(item)"></i>
                <i
                  class="el-icon-video-pause"
                  v-if="item.status === 0"
                  @click.stop="statusHandler(item, 1)"
                ></i>
                <i
                  class="el-icon-video-play"
                  v-if="item.status === 1"
                  @click.stop="statusHandler(item, 0)"
                ></i>
                <i
                  class="el-icon-circle-close"
                  v-if="activeName == $t('marketManagement.released')"
                  @click.stop="statusHandler(item, 2)"
                ></i>
              </div>
            </div>
            <div class="modal"></div>
          </el-card>
          <p class="label">{{ item.name }}</p>
        </div>
      </div>

      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <add-program-modal
      @getData="replaceData"
      :visible.sync="visible"
      :currentItem="currentItem"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddProgramModal from './components/AddProgramModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddProgramModal
  },
  data() {
    return {
      activeName: this.$t('marketManagement.launchProgress'),
      visible: false,
      visible2: false,
      programList: [],
      nameToId: [
        this.$t('marketManagement.launchProgress'),
        this.$t('marketManagement.released'),
        this.$t('marketManagement.complete')
      ],
      currentItem: null
    }
  },

  methods: {
    isImage(str) {
      if (!str) return ''

      let image = false
      const imageList = ['png', 'jpg', 'gif']

      for (let i = 0; i < imageList.length; i++) {
        if (str.includes(imageList[i])) {
          image = true
          break
        }
      }

      return image
    },
    handleClick(tab) {
      this.activeName = tab.name
      this.getData()
    },
    detailHandler(item) {
      this.visible2 = true
      this.currentItem = item
    },

    async statusHandler(row, status) {
      console.log(status)
      try {
        await this.$confirm(
          status === 1
            ? this.$t('marketManagement.pauseLaunch')
            : status === 0
            ? this.$t('marketManagement.pauseLaunch2')
            : this.$t('groupManagement.endLaunch'),
          this.$t('tips'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel')
          }
        )
        this.loading = true

        try {
          const sendData = { id: row.id, status }
          let res = {}
          if (status == 0) {
            res = await this.$api.marketManagement.checkSourceLaunchInfo(row)
          }

          if (res.result) {
            sendData.launchIds = res.result
            await this.$confirm(res.summary, this.$t('tips'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel')
            })
          }

          try {
            await this.$api.marketManagement.programStatus(sendData)
            this.$message.success(this.$t('deviceModal.settingSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          }
        } catch (e) {
          console.log(e)
        }
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    updateHandler(item) {
      this.visible = true
      this.currentItem = item
    },
    async getData() {
      // this.programList = [
      //   {
      //     id: 1,
      //     path:
      //       'https://scp-app-folder.oss-cn-shenzhen.aliyuncs.com/20210310120142298950067.jpg?Expires=1618454770&OSSAccessKeyId=LTAI4FpHNe9F6gHUCvBuDJBH&Signature=cYHWCmkZM3egCb9CHmJYtLZifig%3D',
      //     type: 'photo',
      //     name: '深圳营销广告',
      //     format: 'PNG',
      //     size: '200kb',
      //     ratio: '1920*1080'
      //   }
      // ]

      try {
        this.loading = true
        let status = this.nameToId.findIndex(tab => tab == this.activeName)
        const res = await this.$api.marketManagement.programList({
          sn: this.params.sn,
          status
        })

        this.programList = (res.result || []).map(item => {
          if (item.type == 3) {
            item.isImage = this.isImage(item.path)
          }
          return item
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mainCot {
  position: relative;
  min-height: 400px;
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  .contentWrap {
    overflow: auto;
    flex: 1;
  }

  ::v-deep .el-tabs__item {
    &:hover,
    &.is-active {
      color: #ef2b23;
    }
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #ef2b23;
  }

  .listItem {
    width: 30%;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;

    &:hover {
      .el-card-bottom,
      .el-card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      & > .el-card .modal {
        display: block;
      }
    }

    .el-card {
      position: relative;

      .modal {
        background: rgba(81, 81, 81, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
      }
    }

    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;

      .main {
        display: flex;
        margin: 20px 0;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      video {
        width: 100%;
      }
    }
    .label {
      margin-top: 10px;
      font-weight: bold;
    }
  }

  .el-card-top {
    width: calc(100% - 40px);
    position: absolute;
    top: 10px;
    font-size: 13px;
    font-weight: bold;
    display: none;
    color: #70b603;
  }

  .el-card-bottom {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 10px;
    display: none;

    i {
      font-size: 22px;
      margin: 0 4px;
      color: #ef2b23;
    }
  }
}
</style>
