<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('marketManagement.programName')"
        v-model.trim="params.appName"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <c-button
        type="primary"
        round
        icon="iconxinzeng"
        @click="
          currentItem = null
          visible = true
        "
        >{{ $t('newadd') }}</c-button
      >
    </div>

    <div class="mainCot" v-loading="loading" v-scroll="loadmore">
      <template v-if="programList.length">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="$t('marketManagement.launchProgress')"
            :name="$t('marketManagement.launchProgress')"
          />
          <el-tab-pane
            :label="$t('marketManagement.released')"
            :name="$t('marketManagement.released')"
          />
          <el-tab-pane
            :label="$t('marketManagement.complete')"
            :name="$t('marketManagement.complete')"
          />
        </el-tabs>

        <div
          @click="detailHandler(item)"
          class="listItem"
          v-for="item in programList"
          :key="item.id"
        >
          <el-card>
            <div class="el-card-top">
              <span v-if="item.isLongTermDelivery == 0">{{ $t('loginPage.longTerm') }}</span>
              <span v-else
                >{{ $t('marketManagement.asOf') }}
                {{ $utils.formatTime(item.endDate, 'YYYY-MM-DD') }}</span
              >
              <span>{{ activeName }}</span>
            </div>
            <div class="main">
              <img v-if="item.type === 'photo'" :src="item.path" />
              <video v-else @click.stop :src="item.path"></video>
            </div>

            <div class="el-card-bottom">
              <div>
                <el-tag v-if="item.type !== 'video'" type="primary">{{
                  $t('spaceDetails.photo')
                }}</el-tag>
                <el-tag v-else type="success ">{{ $t('spaceDetails.video') }}</el-tag>
              </div>

              <div style="z-index:100">
                <i class="el-icon-edit" @click.stop="updateHandler(item)"></i>
                <i class="el-icon-video-pause" @click.stop="delHandler(item)"></i>
              </div>
            </div>
            <div class="modal"></div>
          </el-card>
          <p class="label">{{ item.name }}</p>
        </div>
      </template>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <add-program-modal
      @getData="replaceData"
      :visible.sync="visible"
      :current-item="currentItem"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddProgramModal from './components/AddProgramModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddProgramModal
  },
  data() {
    return {
      activeName: this.$t('marketManagement.launchProgress'),
      visible: false,
      visible2: false,
      programList: [],
      currentItem: null
    }
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name
    },
    loadmore() {
      if (this.total < this.params.pageSize) {
        return
      }

      ++this.params.currentPage
      this.getData()
    },
    detailHandler(item) {
      this.visible2 = true
      this.currentItem = item
    },
    async delHandler(row) {
      try {
        await this.$confirm(this.$t('marketManagement.pauseLaunch'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.appManagement.appDelete({ appId: row.appId })
        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    updateHandler(item) {
      this.visible = true
      this.currentItem = item
    },
    async getData() {
      this.programList = [
        {
          id: 1,
          path:
            'https://scp-app-folder.oss-cn-shenzhen.aliyuncs.com/20210310120142298950067.jpg?Expires=1618454770&OSSAccessKeyId=LTAI4FpHNe9F6gHUCvBuDJBH&Signature=cYHWCmkZM3egCb9CHmJYtLZifig%3D',
          type: 'photo',
          name: '深圳营销广告',
          format: 'PNG',
          size: '200kb',
          ratio: '1920*1080'
        }
      ]

      // try {
      //   if (this.noMore && this.params.currentPage !== 1) return

      //   this.loading = true
      //   const res = await this.$api.appManagement.programList(this.params)

      //   if (res.result) {
      //     const list = (res.result.list || []).map(item => {
      //       item.appSize = this.$utils.bytesToSize(item.appSize)
      //       return item
      //     })
      //     this.programList = this.params.currentPage === 1 ? list : this.programList.concat(list)
      //     if (!list.length && this.params.currentPage !== 1) this.noMore = true
      //     this.total = res.result.total

      //   } else {
      //     this.tableData = []
      //     this.total = 0
      //   }
      // } catch (err) {
      //   console.log(err)
      // } finally {
      //   this.loading = false
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.mainCot {
  position: relative;
  min-height: 400px;
  overflow: auto;
  height: 0;
  flex: 1;

  ::v-deep .el-tabs__item {
    &:hover,
    &.is-active {
      color: #ef2b23;
    }
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #ef2b23;
  }

  .listItem {
    width: 30%;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;

    &:hover {
      .el-card-bottom,
      .el-card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      & > .el-card .modal {
        display: block;
      }
    }

    .el-card {
      position: relative;

      .modal {
        background: rgba(81, 81, 81, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
      }
    }

    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;

      .main {
        display: flex;
        margin: 20px 0;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      video {
        width: 100%;
      }
    }
    .label {
      margin-top: 10px;
      font-weight: bold;
    }
  }

  .el-card-top {
    width: calc(100% - 40px);
    position: absolute;
    top: 10px;
    font-size: 13px;
    font-weight: bold;
    display: none;
    color: #70b603;
  }

  .el-card-bottom {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 10px;
    display: none;

    i {
      font-size: 22px;
      margin: 0 4px;
      color: #ef2b23;
    }
  }
}
</style>
